
<template>
    <div class="back-index">
        <div class="cancel" @click="cancel">取消</div>
        <div class="logout" @click="logout">退出</div>
        <div class="info-box">
            <div class="info-txt">
                后续考试报到各项操作的状态和操作视频将被系统记录，请按指引要求认证完成。
            </div>
            <div class="back_checked">
                <van-checkbox v-model="checked">我将按指引要求完成后续各项操作</van-checkbox>
            </div>
            <van-button @click="next" :disabled="!checked">下一步</van-button>
        </div>
    </div>
</template>

<script>
    import {studentStatus} from "r/index/studentStatus";
    import {Dialog, Toast} from 'vant';

    export default {
        name: "openBack",
        data() {
            return {
                checked: false
            };
        },
        created() {
            localStorage.setItem("studentStatusTime",0);
            let exam_uuid = localStorage.getItem("monitor-subjectUuId");
            let sfzNum = localStorage.getItem("monitor-zkz");
            this.studentStatusPost(sfzNum,exam_uuid);
        },
        methods: {
            next() {
                let exam_uuid = localStorage.getItem("monitor-subjectUuId");
                let sfzNum = localStorage.getItem("monitor-zkz");
                studentStatus(sfzNum,exam_uuid).then((res) => {
                    localStorage.setItem("monitor-huanjing", res.data.huanjing);
                    localStorage.setItem("monitor-renzheng", res.data.renzheng);
                    localStorage.setItem("monitor-chengnuoshu", res.data.chengnuoshu);
                    if(res.data.huanjing == 1){
                        this.$router.replace('/promise');
                    }else{
                        this.$router.replace('/envir');
                    }
                });
            },
            cancel() {
                this.$router.replace('/position');
             },
             studentStatusPost(sfzNum,exam_uuid){
                var time1 = Date.parse(new Date());
                let studentStatusTime = localStorage.getItem("studentStatusTime");
                if( time1 > parseInt(studentStatusTime) + 10 || studentStatusTime == 0){
                    studentStatus(sfzNum,exam_uuid).then((res) => {
                        localStorage.setItem("monitor-huanjing", res.data.huanjing);
                        localStorage.setItem("monitor-renzheng", res.data.renzheng);
                        localStorage.setItem("monitor-chengnuoshu", res.data.chengnuoshu);
                        localStorage.setItem("studentStatusTime", time1);
                        if(res.data.renzheng == 0){
                            this.$router.replace('/camera');
                        }
                        if(res.data.huanjing == 1 && res.data.chengnuoshu == 1){
                            this.$router.replace('/position');
                        }
                    });
                }
            },

            // 退出登录
            async logout() {
                Dialog.confirm({
                    title: '提醒',
                    message: '是否退出登录？',
                }).then(() => {
                    Toast('退出成功');
                    setTimeout(() => {
                        this.$store.commit("loginOut");
                        this.$router.replace('/login');
                    }, 1000)
                }).catch(() => {
                    // on cancel
                });

            },
        }
    }
</script>

<style scoped lang="scss">
    .back-index {
        position: relative;
        padding-top: 60px;

        .logout {
            position: absolute;
            right: 20px;
            top: 30px;
            margin: 0;
            color: #8b2d87;
            font-size: 16px;

            &:hover {
                cursor: pointer;
            }
        }

        .cancel {
            position: absolute;
            left: 20px;
            top: 30px;
            margin: 0;
            color: #8b2d87;
            font-size: 16px;
            &:hover {
                cursor: pointer;
            }
        }

        .info-box {
            padding: 100px 15px 0;

            .info-txt {
                text-align: left;
                margin-bottom: 20px;
                font-size: 15px;
                /*font-weight: 600;*/
                /*letter-spacing:1px;*/
            }

            .van-button {
                width: 100%;
                margin-top: 20px;
                background-color: #563279;
                border-color: #563279;
                color: #ffffff;
                border-radius: 4px;
            }
        }

        .camera-index-text-b {
            color: #F5A43C;
        }

        .back_checked {
            text-align: center;
            margin: 15px 0;

            ::v-deep .van-checkbox {
                justify-content: center;

                .van-checkbox__label {
                    font-size: 14px;
                }
                .van-checkbox__icon--checked .van-icon {
                    color: #fff;
                    background-color: #563279;
                    border-color: #563279;
                }
            }
        }
    }

</style>

